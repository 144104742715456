import * as React from "react";

import Layout from "../components/layout";
import { Flex, Box } from "@quandoo-dev/cookbook/lib/layout";
import useTranslation from "../hooks/useTranslation";

interface notFoundProps {
  pageContext: any;
}

const NotFoundPage: React.FC<notFoundProps> = props => {
  const {
    pageContext,
  } = props;

  const country = pageContext.country;
  return (
    <Layout locale={country}>
      <Box style={{ textAlign: "center" }}>
        <h1>{useTranslation(country, "error.whoops")}</h1>
        <p>{useTranslation(country, "error.message")}</p>
        <a style={{ textDecoration: "none", color: "#1870C3" }} href="/">
          {useTranslation(country, "error.cta")}
        </a>
      </Box>
      <Flex justifyContent="center" my="xxxl">
        <img
          src="/404_Error_B2C 1.png"
          alt="404"
          style={{ maxWidth: "100%" }}
        />
      </Flex>
    </Layout>
  );
};

export default NotFoundPage;
